<template>
  <div class="main-page">
    <banners-in-main-page-skeleton v-if="isMainSlidesLoading || isNearSlidesLoading" is-container />
    <banners-in-main-page
      v-else
      :is-authorized="isAuthorized"
      :location-code="serverLocationCode"
      :main-slides="mainSlides"
      :near-slides="nearSlides"
    />
    <client-only>
      <shelf-products-skeleton
        v-if="isProductsRecommendationLoading"
        :show-buttons="false"
      />
      <recommendation-cashback-banner v-else-if="!productsRecommendation.length && isAuthorized" />
      <shelf-products
        v-else-if="productsRecommendation.length"
        title="Рекомендуем почитать"
        :more-link="{title: 'Все рекомендации', link: '/personal/recommendation/'}"
        :products="productsRecommendation"
        :show-buttons="false"
      />
      <template #placeholder>
        <shelf-products-skeleton :show-buttons="false" />
      </template>
    </client-only>
    <shelf-products-skeleton v-if="isProductsNoveltyLoading" :show-buttons="false" />
    <shelf-products
      v-else-if="!isProductsNoveltyLoading && productsNovelty.length"
      title="Новинки"
      shelf-id="NovieKnigi"
      :more-link="{title: 'Все новинки', link: '/novie-knigi/'}"
      :products="productsNovelty"
      :show-buttons="false"
    />
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <snippet-list
        v-if="afterNewBooksSnippetsList.length && !afterNewBooksSnippetsLoading"
        :snippet-index="1"
        :title="afterNewBooksSnippetsTitle"
        :snippets="afterNewBooksSnippetsList"
      />
      <snippet-list-skeleton v-else-if="afterNewBooksSnippetsLoading" />
    </lazy-hydrate>
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <shelf-products-skeleton v-if="isProductsBestsellerLoading" :show-buttons="false" />
      <shelf-products
        v-else-if="!isProductsBestsellerLoading && productsBestseller.length"
        title="Бестселлеры"
        shelf-id="KnigiBestsellery"
        :more-link="{ title: 'Все бестселлеры', link: '/knigi-bestsellery/' }"
        :products="productsBestseller"
        :show-buttons="false"
      />
    </lazy-hydrate>
    <lazy-hydrate :when-visible="{ rootMargin: '100px' }">
      <shelf-products-skeleton
        v-if="isProductsExclusiveLoading"
        :show-buttons="false"
      />
      <shelf-products
        v-else-if="!isProductsExclusiveLoading && productsExclusive.length"
        title="Эксклюзив"
        shelf-id="KnigiExclusive"
        :products="productsExclusive"
        :show-buttons="false"
      />
    </lazy-hydrate>
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <snippet-list
        v-if="afterBestsellersSnippetsList.length && !afterBestsellersSnippetsLoading"
        :snippet-index="2"
        :title="afterBestsellersSnippetsTitle"
        :snippets="afterBestsellersSnippetsList"
      />
      <snippet-list-skeleton v-else-if="afterBestsellersSnippetsLoading" />
    </lazy-hydrate>
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <shelf-products-skeleton v-if="isProductsWithAutographsLoading" :show-buttons="false" />
      <shelf-products
        v-else-if="!isProductsWithAutographsLoading && productsWithAutographs.length"
        title="Книги с автографом автора"
        shelf-id="KnigiSAvtografom4435"
        :more-link="{ title: 'Все книги', link: '/catalog/knigi-s-avtografom-4435/' }"
        :products="productsWithAutographs"
        :show-buttons="false"
      />
    </lazy-hydrate>
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <snippet-list
        v-if="afterAutographsSnippetsList.length && !afterAutographsSnippetsLoading"
        :snippet-index="3"
        :title="afterAutographsSnippetsTitle"
        :snippets="afterAutographsSnippetsList"
      />
      <snippet-list-skeleton v-else-if="afterAutographsSnippetsLoading" />
    </lazy-hydrate>
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <shelf-products-skeleton v-if="isProductsPopularLoading" :show-buttons="false" />
      <shelf-products
        v-else-if="!isProductsPopularLoading && productsPopular.length"
        title="Лучшие цены"
        shelf-id="BestPrice"
        :more-link="{ title: 'Все книги', link: '/best-price/' }"
        :products="productsPopular"
        :show-buttons="false"
      />
    </lazy-hydrate>
    <client-only>
      <shelf-rating-skeleton v-if="isLoadingRatings" />
      <shelf-rating
        v-else-if="ratings.length"
        :items="ratings"
      />

      <template #placeholder>
        <shelf-rating-skeleton />
      </template>
    </client-only>
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <app-journal />
    </lazy-hydrate>
    <client-only>
      <popular-authors-slider />
    </client-only>
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <publisher-list />
    </lazy-hydrate>
    <lazy-hydrate
      :when-visible="{ rootMargin: '100px' }"
    >
      <main-about />
    </lazy-hydrate>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  useMeta,
  useStore,
  useFetch,
  reactive,
  toRefs,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';

import LazyHydrate from 'vue-lazy-hydration';

import {
  AFTER_NEW_BOOKS_SNIPPETS_ID,
  AFTER_BESTSELLERS_SNIPPETS_ID,
  AFTER_AUTOGRAPHS_SNIPPETS_ID,
} from '@/utils/constants/snippetConstants';
import { DESCRIPTION_MAIN_PAGE, TITLE_MAIN_PAGE } from '@/utils/constants/headConstants';
import { onScrollToHash } from '@/utils/scrollTo';

import useHttpClient from '@/compositions/useHttpClient';
import useProductsNovelty from '@/compositions/shelfs/useProductsNovelty';
import useProductsBestseller from '@/compositions/shelfs/useProductsBestseller';
import useProductsExclusive from '@/compositions/shelfs/useProductsExclusive';
import useProductsRecommendation from '@/compositions/shelfs/useProductsRecommendation';
import useProductsWithAutographs from '@/compositions/shelfs/useProductsWithAutographs';
import useProductsBestPrice from '@/compositions/shelfs/useProductsBestPrice';
import useUrl from '@/compositions/useUrl';
import useMainBanners from '@/compositions/mainPage/useMainBanners';
import useRatings from '@/compositions/ratings/useRatings';
import useSnippets from '@/compositions/catalog/useSnippets';

import formattedPicturesCdnBook24 from '@/utils/formatters/formattedPicturesCdnBook24';

import ShelfProducts from '@/components/ShelfProducts';
import ShelfProductsSkeleton from '@/components/ShelfProducts/skeleton';
import SnippetList from '@/components/SnippetList';
import SnippetListSkeleton from '@/components/SnippetList/skeleton';
import BannersInMainPage from '@/components/BannersInMainPage';
import BannersInMainPageSkeleton from '@/components/BannersInMainPage/skeleton';
import AppJournal from '@/components/AppJournal';
import PopularAuthorsSlider from '@/components/PopularAuthorsSlider';
import PublisherList from '@/components/PublisherList';
import MainAbout from '@/components/MainAbout';
import ShelfRating from '@/components/ShelfRating';
import ShelfRatingSkeleton from '@/components/ShelfRating/skeleton';
import RecommendationCashbackBanner from '@/components/Recommendations/RecommendationCashbackBanner';

export default defineComponent({
  name: 'MainPage',
  components: {
    ShelfRating,
    ShelfRatingSkeleton,
    ShelfProducts,
    ShelfProductsSkeleton,
    SnippetList,
    SnippetListSkeleton,
    BannersInMainPage,
    BannersInMainPageSkeleton,
    AppJournal,
    PopularAuthorsSlider,
    PublisherList,
    MainAbout,
    RecommendationCashbackBanner,
    LazyHydrate,
  },
  setup() {
    const route = useRoute();
    const { state } = useStore();
    const httpClient = useHttpClient();
    const productsNovelty = useProductsNovelty();
    const productsBestseller = useProductsBestseller();
    const productsExclusive = useProductsExclusive();
    const productsRecommendation = useProductsRecommendation();
    const productsWithAutographs = useProductsWithAutographs();
    const productsBestPrice = useProductsBestPrice();
    const ratingsComposition = useRatings();
    const afterNewBooksSnippets = useSnippets({ snippetId: AFTER_NEW_BOOKS_SNIPPETS_ID });
    const afterBestsellersSnippets = useSnippets({ snippetId: AFTER_BESTSELLERS_SNIPPETS_ID });
    const afterAutographsSnippets = useSnippets({ snippetId: AFTER_AUTOGRAPHS_SNIPPETS_ID });
    const urlComposition = useUrl();
    const mainBanners = useMainBanners();

    const description = DESCRIPTION_MAIN_PAGE.replace('$phone', state.main.currentPhone);

    if (state.isAuthorized) {
      productsRecommendation.isProductsRecommendationLoading.value = true;
    }

    const localState = reactive({
      nearSlides: [],
      isNearSlidesLoading: false,
      ratings: [],
    });

    const getNearBanners = async () => {
      localState.isNearSlidesLoading = true;
      await httpClient.content.getBanners({ type: 'MAIN_PAGE_NEAR_SLIDER', url: urlComposition.getCurrentUrl() })
        .then((list) => {
          let result = list;
          if (list.length > 2) result = list.slice(0, 2);
          return result.map(item => ({
            id: item.id,
            name: item.name,
            url: item.url,
            image: formattedPicturesCdnBook24(item.images[0].src, {
              sizePicHeight: 150,
              sizePicWidth: 420,
            }),
          }));
        })
        .then((list) => {
          localState.nearSlides = list;
        })
        .catch(() => {
          localState.nearSlides = [];
        })
        .finally(() => {
          localState.isNearSlidesLoading = false;
        });
    };

    const getProductsRecommendation = async () => {
      if (state.isAuthorized) {
        await productsRecommendation.getProductsRecommendation();
      }
    };

    const getRatings = async () => {
      await ratingsComposition.getRatings();
      localState.ratings = ratingsComposition.commonCategory.value.ratings;
    };

    useFetch(async () => {
      await Promise.all([
        productsNovelty.getProductsNovelty({ sortPreset: 'random' }),
        productsBestPrice.getProducts(),
        productsWithAutographs.getProducts(),
        productsBestseller.getProductsBestseller({ sortPreset: 'random' }),
        productsExclusive.getProductsExclusive(),
        mainBanners.getMainBanners(),
        getNearBanners(),
        afterNewBooksSnippets.getSnippetList(),
        afterBestsellersSnippets.getSnippetList(),
        afterAutographsSnippets.getSnippetList(),
      ]);
    });

    useMeta({
      title: TITLE_MAIN_PAGE,
      meta: [
        { hid: 'description', name: 'description', content: description },
        { hid: 'title', name: 'title', content: TITLE_MAIN_PAGE },
        { hid: 'og:title', property: 'og:title', content: TITLE_MAIN_PAGE },
        { hid: 'og:description', property: 'og:description', content: description },
      ],
    });

    onMounted(async () => {
      if (route.value.hash) {
        onScrollToHash(route.value.hash);
      }
      await getProductsRecommendation();
      await getRatings();
    });

    watch(
      () => route.value.hash,
      value => onScrollToHash(value),
      { immediate: false },
    );

    return {
      ...toRefs(localState),
      productsNovelty: productsNovelty.productsNovelty,
      isProductsNoveltyLoading: productsNovelty.isProductsNoveltyLoading,
      productsBestseller: productsBestseller.productsBestseller,
      isProductsBestsellerLoading: productsBestseller.isProductsBestsellerLoading,
      productsExclusive: productsExclusive.productsExclusive,
      isProductsExclusiveLoading: productsExclusive.isProductsExclusiveLoading,
      productsRecommendation: productsRecommendation.productsRecommendation,
      isProductsRecommendationLoading: productsRecommendation.isProductsRecommendationLoading,
      mainSlides: mainBanners.list,
      isMainSlidesLoading: mainBanners.isLoading,
      productsWithAutographs: productsWithAutographs.products,
      isProductsWithAutographsLoading: productsWithAutographs.isLoading,
      productsPopular: productsBestPrice.products,
      isProductsPopularLoading: productsBestPrice.isLoading,
      isAuthorized: state.isAuthorized,
      serverLocationCode: state.serverLocationCode,
      isLoadingRatings: ratingsComposition.isLoadingRatings,
      afterNewBooksSnippetsTitle: afterNewBooksSnippets.title,
      afterNewBooksSnippetsList: afterNewBooksSnippets.snippets,
      afterNewBooksSnippetsLoading: afterNewBooksSnippets.isLoading,
      afterBestsellersSnippetsTitle: afterBestsellersSnippets.title,
      afterBestsellersSnippetsList: afterBestsellersSnippets.snippets,
      afterBestsellersSnippetsLoading: afterBestsellersSnippets.isLoading,
      afterAutographsSnippetsTitle: afterAutographsSnippets.title,
      afterAutographsSnippetsList: afterAutographsSnippets.snippets,
      afterAutographsSnippetsLoading: afterAutographsSnippets.isLoading,
    };
  },
  head: {},
});
</script>
